import React, {FormEvent} from "react";

export default function MainMenuComponent() {
    const [isFollowOpen, setIsFollowOpen] = React.useState(false);

    function toggleFollowOpen(e: FormEvent) {
        e.preventDefault();
        e.stopPropagation();

        setIsFollowOpen((isFollowOpen) => !isFollowOpen);
    }

    return (
        <ul className="main">
            <li className="menuitem"><a href="/" title="Homepage">Home</a></li>
            <li className="separator menuitem">&#8275;</li>
            <li className="menuitem"><a href="/about" title="Captain Lyubomerica Biography">About</a></li>
            <li className="separator menuitem">&#8275;</li>
            <li className="menuitem"><a href="/booking"
                                        title="Book Captain Lyubomerica for your project! :D">Booking</a></li>
            <li className="separator menuitem">&#8275;</li>
            <li className="menuitem">
                <a href="/follow" title="Follow me on&hellip;" onClick={toggleFollowOpen}>Follow</a>
                {isFollowOpen &&
                    <div className="popup">
                        <ul className="popup">
                            <li>
                                <a href="https://www.instagram.com/lyubomerica" title="Instagram" target="_blank">
                                    <span className="icon icon-instagram">&nbsp;</span>
                                    Instagram
                                </a>
                            </li>
                            <li className="separator"></li>
                            <li>
                                <a href="https://www.youtube.com/@lyubomerica" title="Youtube" target="_blank">
                                    <span className="icon icon-youtube">&nbsp;</span>
                                    YouTube
                                </a>
                            </li>
                        </ul>
                    </div>
                }
            </li>
        </ul>
    )
}