import React, {FormEvent} from "react";
import MainMenuComponent from "./MainMenuComponent";

export default function DeskTopNavigationComponent() {


    return (
        <nav className="desktop navigation">
            <MainMenuComponent />
        </nav>
    )
}