import React from 'react';
import './App.css';
import HeaderComponent from "./Components/Global/HeaderComponent";
import FooterComponent from "./Components/Global/FooterComponent";
import HomePageComponent from "./Components/Pages/HomePageComponent";
import AboutPageComponent from "./Components/Pages/AboutPageComponent";
import BookPageComponent from "./Components/Pages/BookPageComponent";
import {Route, Routes} from "react-router-dom";

function App() {
  return (
      <div className="App">
          <HeaderComponent></HeaderComponent>
          <div className="scroller">
              <div className="clear offset-header"></div>
              <Routes>
                  <Route path="/" element={<HomePageComponent/>}/>
                  <Route path="/about" element={<AboutPageComponent/>}/>
                  <Route path="/booking" element={<BookPageComponent/>}/>
              </Routes>
              <div className="clear offset-footer"></div>
          </div>
          <FooterComponent></FooterComponent>
      </div>
  );
}

export default App;
