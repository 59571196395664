import "./HeaderComponent.css"
import logo from '../../logo.png';
import DeskTopNavigationComponent from "./DesktopNavigationComponent";
import MobileNavigationComponent from "./MobileNavigationComponent";

export default function HeaderComponent() {
    return (
        <header className="bg-white dark:bg-dark">
            <a href="/" title="Lyubomerica.com" className="logo"><img alt="logo" src={logo}/></a>

            <DeskTopNavigationComponent />
            <MobileNavigationComponent />
        </header>
    )
}