import {Alert, Grid2} from "@mui/material";
export default function BookPageComponent() {
    return (
        <Grid2 container spacing={1}>
            <Grid2 size={{sm: 12, md: 8, xl: 4}}>
                <section className="box">
                    <hgroup>
                        <h1>Booking Form</h1>
                        <h2>Reserve your adventure with Cpt. Lyubomerica</h2>
                    </hgroup>
                    <p>
                        Please enter as much details as possible in order to move your reservation forward as swiftly as possible.
                        Feel free to share your idea even if you are not sure what would be the right approach.
                    </p>
                    {/*<p>*/}
                    {/*    Due to high number of booking requests, it may take up to a week to answer.*/}
                    {/*</p>*/}

                    <form>
                        <Grid2 container spacing={1}>
                            <Grid2 size={12}><Alert severity="warning">This form is not operational yet.</Alert></Grid2>

                            <Grid2 size={{sm: 12, md: 6}}>
                                <input name="firstname" id="firstname" type="text" placeholder="First Name"/>
                            </Grid2>
                            <Grid2 size={{sm: 12, md: 6}}>
                                <input name="lastname" id="lastname" type="text" placeholder="Last Name"/>
                            </Grid2>
                            <Grid2 size={{sm: 12, md: 6}}>
                                <input name="email" id="email" type="email" placeholder="Email Address"/>
                            </Grid2>
                            <Grid2 size={{sm: 12, md: 6}}>
                                <input name="phone" id="phone" type="text" placeholder="Phone Number (+1...)"/>
                            </Grid2>
                            <Grid2 size={12}>
                                <select name="reason" id="reason">
                                    <option>Select a booking reason&hellip;</option>
                                    <optgroup label="Cruise Sailing">
                                        <option>Crew Member</option>
                                        <option>Skipper</option>
                                    </optgroup>
                                    <optgroup label="Boat Delivery">
                                        <option>Crew Member</option>
                                        <option>Skipper + Crew</option>
                                    </optgroup>
                                    <optgroup label="Regattas">
                                        <option>Mainsail Trimmer</option>
                                        <option>Jib/Spinnaker Trimmer</option>
                                        <option>Pitman</option>
                                        <option>Skipper</option>
                                    </optgroup>
                                    <optgroup label="Contruction Works">
                                        <option>Boat Repairs and Improvements</option>
                                        <option>Home Repairs & Improvements</option>
                                        <option>Car Repairs & Improvements</option>
                                        <option>Building Smart Home</option>
                                        <option>Garden and Nature</option>
                                        <option>Tiny Houses</option>
                                        <option>Other</option>
                                    </optgroup>
                                    <optgroup label="Software Engineering & System Administration">
                                        <option>Programming Languages</option>
                                        <option>Adopting new technology (stack)</option>
                                        <option>Server Management</option>
                                        <option>AWS</option>
                                        <option>GCP</option>
                                        <option>Azure</option>
                                        <option>FinOps</option>
                                        <option>Other</option>
                                    </optgroup>
                                </select>
                            </Grid2>
                            <Grid2 size={12}>
                                <textarea name="message" id="message" placeholder="Details of your booking request" rows={12}></textarea>
                            </Grid2>
                            <Grid2 size={6}><input type="submit" value="Send" disabled={true}/></Grid2>
                            <Grid2 size={6}><input type="reset" value="Cancel" disabled={true}/></Grid2>
                        </Grid2>
                    </form>
                </section>
            </Grid2>
            <Grid2 size={{sm: 12, md: 4, xl: 4}}>
                <section className="box">
                    <hgroup>
                        <h1>Latest Projects</h1>
                        <h2>What has been done so far&hellip;</h2>
                    </hgroup>
                    <ul className="feed">
                        <li className="feed-item">
                            <hgroup>
                                <h3>Main Sailor - North Aegean Cup</h3>
                                <h4>03 Aug 2024</h4>
                            </hgroup>
                            <p>Being assigned as mainsail trimmer for 5 days in a row, taking 7<sup>th</sup> place in a competitive performance division with some Olympic Champions.</p>
                            <p>Includes boat delivery: Kavala&harr;Porto Carras.</p>
                        </li>
                        <li className="feed-item">
                            <hgroup>
                            <h3>Main Sailor - Friendship Regatta</h3>
                                <h4>27 Jun 2024</h4>
                            </hgroup>
                            <p>Participated in Friendship Regatta as a mainsail trimmer. Got 2<sup>nd</sup> place overall in the area of north-eastern Aegean Sea and Samothrace.</p>
                            <p>Includes boat delivery: Kavala&harr;Samothrace.</p>
                        </li>
                        <li className="feed-item">
                            <hgroup>
                            <h3>Main Sailor - Hydra Regatta</h3>
                                <h4>27 Mar 2024</h4>
                            </hgroup>
                            <p>Got 27<sup>th</sup> and 19<sup>th</sup> positions in the two races Athens&harr;Hydra in a highly competitive performance division with Olympic Champions.</p>
                            <p>Includes boat delivery both ways throughout the entire Aegean sea: Kavala&harr;Athens.</p>
                        </li>
                        <li className="feed-item">
                            <hgroup>
                                <h3>Installing cruise control</h3>
                                <h4>10 Feb 2024</h4>
                            </hgroup>
                            <p>An adaptive cruise control was added on Škoda Superb 2019 successfully with original parts.</p>
                        </li>
                    </ul>
                </section>
            </Grid2>
        </Grid2>
    )
}