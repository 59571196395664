import {Grid2} from "@mui/material"
import './AboutPageComponent.css'
import badge from '../../badge.jpg'

export default function AboutPageComponent() {
    return (
        <Grid2 container spacing={1}>
            <Grid2 size={{sm: 12, md: 8, xl: 4}}>
                <section className="box">
                    <hgroup>
                        <h1>About Me</h1>
                        <h2>Who TF is Captain Lyubomerica?</h2>
                    </hgroup>
                    <p>
                        Hello, I am <a href="https://www.linkedin.com/in/lyubomir-gardev/" title="Linked In" target="_blank">Lyubomir Gardev</a> from <a href="https://maps.app.goo.gl/vCcdoU8HvWy9UijD9" title="See it on the map" target="_blank">Sofia, Bulgaria</a> and this is my personal online brand for social media.
                        I hope you find accommodating my online harbor here and you find inspiring all the diverse projects that I am working on. My interests area spanning in many different areas, such as:
                    </p>
                    <ul className="listing smaller">
                        <li><b>Sailing & Regattas</b> - <a href="https://www.rya.org.uk/training/courses/coastal-skipper-practical-sailing-course-css" title="RYA Coastal Skippper Course" target="_blank">RYA Coastal Skipper</a>, travelled more than 2000 nautical miles (nm)</li>
                        <li><b>Investment and Trading</b> - running a diverse portfolio of assets</li>
                        <li><b>Builder</b> - home and car repairs and improvements, concrete laying, restoration and more&hellip;</li>
                        <li><b>Software Engineering</b> - after all I have 18 years of professional experience in this industry</li>
                        <li><b>Health & Well-being</b> - gym rat, nutrition experimenter and well-being enthusiast</li>
                        <li><b>Semi-professional Influencer</b> - a content creator and a wanna-be influencer</li>
                    </ul>
                </section>
            </Grid2>
            <Grid2 size={{sm: 12, md: 4, xl: 4}} className="center">
                <img alt="Badge" src={badge} className="badge" />
                <a href="https://www.linkedin.com/in/lyubomir-gardev/" title="Linked In" target="_blank" className="button">Contact me on LinkedIn</a>
            </Grid2>
        </Grid2>
    );
}