import "./FooterComponent.css"

export default function FooterComponent() {
    let date = new Date()
    let year = date.getFullYear()

    return (
        <footer>
            <p>Copyright &copy; {year} Captain Lyubomerica. All rights reserved.</p>
            <nav>
                <ul>
                    <li><a href="/terms" title="Terms of service">Terms of use</a></li>
                    <li className="separator">&bull;</li>
                    <li><a href="/privacy" title="Privacy Policy">Privacy Policy</a></li>
                    <li className="separator">&bull;</li>
                    <li><a href="/sitemap" title="Sitemap of the entire website">Sitemap</a></li>
                </ul>
            </nav>
        </footer>
    );
}