import React from 'react'
import {MdOutlineMenu, MdClose} from "react-icons/md"
import MainMenuComponent from "./MainMenuComponent";

export default function MobileNavigationComponent() {
    const [isOpen, setIsOpen] = React.useState(false);
    const menu = <MdOutlineMenu className="toggle" size="32px" color="#245e55" onClick={() => setIsOpen(!isOpen)} />
    const close = <MdClose className="toggle" size="32px" color="#245e55" onClick={() => setIsOpen(!isOpen)} />

    return (
        <nav className="mobile navigation">
            {isOpen ? close : menu}
            {isOpen && <MainMenuComponent />}
        </nav>
    )
}