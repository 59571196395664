import './HomePageComponent.css'
import {InstagramEmbed} from "react-social-media-embed"
import {YouTubeEmbed} from "react-social-media-embed"
import {Grid2} from "@mui/material";

export default function HomePageComponent() {
    return (
      <section id="home-page">
        <Grid2 container spacing={1}>
            <Grid2 size={{md: 12, lg: 8}}>
                <YouTubeEmbed width="100%" url="https://www.youtube.com/embed/1NHNK36hqkg?si=KWqZJhv6zC41pwWl"></YouTubeEmbed>
            </Grid2>
            <Grid2 size={{sm: 12, lg: 4}}>
                <InstagramEmbed url="https://www.instagram.com/p/C-axe2_s8yz"></InstagramEmbed>
            </Grid2>
        </Grid2>
      </section>
    );
}